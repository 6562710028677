import { CCol, CRow } from '@coreui/react-pro'
import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="footerEvents px-5">
                <CRow>
                    <CCol md={8}>
                        <div className="footer__addr px-5">
                            <h1 className="">Aashwasan</h1>

                            <h2>Contact  us</h2>

                            <address>
                                Address
                                <br />
                                # 405, 3 rd A Cross, HRBR Layout, II Block, Bangalore – 560043, India
                                <br />
                                Phone :
                                +91 8530039666 / +91 8530968666 / +91 8530972666

                                <a className="buttonFooter" href="info@aashwasan.com">Email Us</a>
                            </address>
                        </div>
                    </CCol>

                    <CCol md={4}>
                        <ul className="footer__nav px-5">

                            <li className="nav__item">
                                <h2 className="nav__title">Platforms</h2>

                                <ul className="nav__ul">
                                    <li>
                                        <a href="#">Instagram</a>
                                    </li>
                                    <li>
                                        <a href="#">X - Twitter</a>
                                    </li>

                                    <li>
                                        <a href="#">linkedIn</a>
                                    </li>

                                    <li>
                                        <a href="#">Facebook</a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav__item">
                                <h2 className="nav__title">Legal</h2>

                                <ul className="nav__ul">
                                    <li>
                                        <a href="#">Privacy Policy</a>
                                    </li>

                                    <li>
                                        <a href="#">Terms of Use</a>
                                    </li>

                                    {/* <li>
                                        <a href="#">Sitemap</a>
                                    </li> */}
                                </ul>
                            </li>
                        </ul>
                    </CCol>
                </CRow>

                <div className="legal px-5">
                    <p>&copy; 2019. All rights reserved.</p>

                    <div className="legal__links">
                        <span>Team Aashwasan <span className="heart">♥</span></span>
                    </div>
                </div>
            </footer >
        </>
    )
}

export default Footer