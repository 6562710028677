import { CCol } from '@coreui/react-pro'
import React from 'react'

const ConnectComponent = () => {
    return (
        <>
            <CCol className='px-5 py-3 ConnectComponent'>
                <div className='mx-5 mb-5'>
                    <h3>Tell us what you need</h3>
                    <h5>This will help us curate services specially for you!</h5>
                </div>

                {/* <div style={{ height: '200px' }}>

                </div> */}

                <div className='mx-5 mb-3 d-flex justify-content-start'>
                    <button type="button" className="btn btn-dark ">Get started</button>
                </div>

            </CCol >
        </>
    )
}

export default ConnectComponent