import React, { useState } from 'react'
import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react-pro'
import logo1 from '../../assets/images/avatars/1.jpg'
import logo3 from '../../assets/images/avatars/2.jpg'
import logo2 from '../../assets/images/avatars/3.jpg'
import logo4 from '../../assets/images/avatars/4.jpg'
import logo5 from '../../assets/images/avatars/5.jpg'
import logo6 from '../../assets/images/avatars/6.jpg'

const EventsComponent = () => {

    const [detailsVisible, setDetailsVisible] = useState(false)

    const handleClick = () => {
        setDetailsVisible(true)
    }

    return (
        <>
            <CCol className='px-5 py-3 EventComponent'>
                <div className='px-5'>
                    <h3>Events</h3>
                </div>

                <div className='d-flex justify-content-evenly'>

                    <CCol>
                        <CRow className='d-flex justify-content-center m-5'>
                            <CCol md={4}>
                                <div className="card">
                                    <img
                                        src={logo2}
                                        className="card-img-top"
                                        alt="..."
                                        height='250px'
                                    />
                                    <div className="card-body">
                                        <h6 className="card-title">Card title</h6>
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the cards content.Some quick example text to build on the card title and make up the bulk of the cards content.</p>
                                        <h6 className="card-text">Venue: </h6>
                                        <h6 className="card-text">Scheduled on - 07/08</h6>
                                        <div className='d-flex justify-content-between align-content-center'>
                                            <p className="card-text">Paid</p>
                                            <CButton className='btnColor' onClick={handleClick}>View details</CButton>
                                            <CModal
                                                visible={detailsVisible}
                                                onClose={() => setDetailsVisible(false)}
                                                aria-labelledby="LiveDemoExampleLabel"
                                                alignment='center'
                                            >
                                                <CModalHeader>
                                                    <CModalTitle id="LiveDemoExampleLabel">Event name</CModalTitle>
                                                </CModalHeader>
                                                <CModalBody>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                </CModalBody>
                                                <CModalFooter>
                                                    <CButton color="secondary" onClick={() => setDetailsVisible(false)}>
                                                        Close
                                                    </CButton>
                                                </CModalFooter>
                                            </CModal>
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md={4}>
                                <div className="card">
                                    <img
                                        src={logo2}
                                        className="card-img-top"
                                        alt="..."
                                        height='250px'
                                    />
                                    <div className="card-body">
                                        <h6 className="card-title">Card title</h6>
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the cards content.Some quick example text to build on the card title and make up the bulk of the cards content.</p>
                                        <h6 className="card-text">Venue: </h6>
                                        <h6 className="card-text">Scheduled on - 07/08</h6>
                                        <div className='d-flex justify-content-between align-content-center'>
                                            <p className="card-text">Paid</p>
                                            <CButton className='btnColor' onClick={handleClick}>View details</CButton>
                                            <CModal
                                                visible={detailsVisible}
                                                onClose={() => setDetailsVisible(false)}
                                                aria-labelledby="LiveDemoExampleLabel"
                                                alignment='center'
                                            >
                                                <CModalHeader>
                                                    <CModalTitle id="LiveDemoExampleLabel">Event name</CModalTitle>
                                                </CModalHeader>
                                                <CModalBody>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                </CModalBody>
                                                <CModalFooter>
                                                    <CButton color="secondary" onClick={() => setDetailsVisible(false)}>
                                                        Close
                                                    </CButton>
                                                </CModalFooter>
                                            </CModal>
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md={4}>
                                <div className="card">
                                    <img
                                        src={logo2}
                                        className="card-img-top"
                                        alt="..."
                                        height='250px'
                                    />
                                    <div className="card-body">
                                        <h6 className="card-title">Card title</h6>
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the cards content.Some quick example text to build on the card title and make up the bulk of the cards content.</p>
                                        <h6 className="card-text">Venue: </h6>
                                        <h6 className="card-text">Scheduled on - 07/08</h6>
                                        <div className='d-flex justify-content-between align-content-center'>
                                            <p className="card-text">Paid</p>
                                            <CButton className='btnColor' onClick={handleClick}>View details</CButton>
                                            <CModal
                                                visible={detailsVisible}
                                                onClose={() => setDetailsVisible(false)}
                                                aria-labelledby="LiveDemoExampleLabel"
                                                alignment='center'
                                            >
                                                <CModalHeader>
                                                    <CModalTitle id="LiveDemoExampleLabel">Event name</CModalTitle>
                                                </CModalHeader>
                                                <CModalBody>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                </CModalBody>
                                                <CModalFooter>
                                                    <CButton color="secondary" onClick={() => setDetailsVisible(false)}>
                                                        Close
                                                    </CButton>
                                                </CModalFooter>
                                            </CModal>
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>

                    </CCol>

                </div>

                <div className='d-flex justify-content-center'>
                    <button type="button" className="btn btn-dark">View all events</button>
                </div>

            </CCol >
        </>
    )
}

export default EventsComponent