import React, {useState , Component }  from 'react';
import { NavLink } from 'react-router-dom'

import {
  CContainer,
  CNavbarBrand,
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
  CForm,
  CFormInput,
  CButton,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CNavbar,
  CImage,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilApplicationsSettings, cilMenu } from '@coreui/icons'




import logo from 'src/assets/images/playstore.png'

const Header = () => {
  const [visible, setVisible] = useState(false);

  return (
    <CNavbar expand="lg" className="bg-body-tertiary">
    <CContainer fluid>
    <CNavbarBrand href="#">
            <img
              src="https://intranet.aashwasan.net/static/media/playstore.5c678d1e89353705c4b4.png"
              alt="Aashwasan Logo"
              height="30"
            />
          </CNavbarBrand>
      <CNavbarToggler onClick={() => setVisible(!visible)} />
      <CCollapse className="navbar-collapse" visible={visible}>
        <CNavbarNav>
          <CNavItem>
            <CNavLink href="#" active>
              Home
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Link</CNavLink>
          </CNavItem>

          <CNavItem>
            <CNavLink href="#" disabled>
              Disabled
            </CNavLink>
          </CNavItem>
        </CNavbarNav>
        {/* <CForm className="d-flex">
          <CFormInput type="search" className="me-2" placeholder="Search" />
          <CButton type="submit" color="success" variant="outline">
            Search
          </CButton>
        </CForm> */}
      </CCollapse>
    </CContainer>
  </CNavbar>
  );
};

export default Header
