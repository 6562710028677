import React from 'react'
import { CCol } from '@coreui/react-pro'
import logo1 from '../../assets/images/avatars/1.jpg'
import logo3 from '../../assets/images/avatars/2.jpg'
import logo2 from '../../assets/images/avatars/3.jpg'
import logo4 from '../../assets/images/avatars/4.jpg'
import logo5 from '../../assets/images/avatars/5.jpg'
import logo6 from '../../assets/images/avatars/6.jpg'

const PractitionerComponent = () => {
    return (
        <>
            <CCol className='my-3 mx-5'>
                <div className='mx-5 mb-5'>
                    <h3>Meet our practitioners</h3>
                </div>
                <div className='d-flex justify-content-around'>
                    <div className="text-center">
                        <img
                            src={logo1}
                            className="rounded-circle"
                            alt="..."
                            height='150px'
                            width='150px'
                        />
                        <h6 className='mt-2'>Prac1</h6>
                    </div>
                    <div className="text-center">
                        <img
                            src={logo2}
                            className="rounded-circle"
                            alt="..."
                            height='150px'
                            width='150px'
                        />
                        <h6 className='mt-2'>Prac2</h6>
                    </div>
                    <div className="text-center">
                        <img
                            src={logo3}
                            className="rounded-circle"
                            alt="..."
                            height='150px'
                            width='150px'
                        />
                        <h6 className='mt-2'>Prac3</h6>
                    </div>
                    <div className="text-center">
                        <img
                            src={logo4}
                            className="rounded-circle"
                            alt="..."
                            height='150px'
                            width='150px'
                        />
                        <h6 className='mt-2'>Prac4</h6>
                    </div>
                    <div className="text-center">
                        <img
                            src={logo5}
                            className="rounded-circle"
                            alt="..."
                            height='150px'
                            width='150px'
                        />
                        <h6 className='mt-2'>Prac5</h6>
                    </div>
                    <div className="text-center">
                        <img
                            src={logo6}
                            className="rounded-circle"
                            alt="..."
                            height='150px'
                            width='150px'
                        />
                        <h6 className='mt-2'>Prac6</h6>
                    </div>
                </div>
            </CCol>
        </>
    )
}

export default PractitionerComponent