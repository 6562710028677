
import React from 'react';
import Headers from '../../components/events/shared/Header'
import {
  CCarousel, CCarouselItem, CImage, CCarouselCaption, CButton
} from '@coreui/react-pro';
import ReactImg from '../../assets/images/react.jpg';
import VueImg from '../../assets/images/vue.jpg';
import AngularImg from '../../assets/images/angular.jpg'
import '../../App.css'
import EventsComponent from './EventsComponent';
import ExploreComponent from './ExploreComponent';
import ConnectComponent from './ConnectComponent';
import Footer from './Footer';
import PractitionerComponent from './PractitionerComponent';

const EventHome = () => {

  return (
    <>
      <Headers />
      <div>
        <CCarousel controls indicators dark>
          <CCarouselItem>
            <CImage className="d-block w-100" src={ReactImg} alt="slide 1" height='450px' />
            <CCarouselCaption className="d-none d-md-block">
              <h5>First slide label</h5>
              <p>Some representative placeholder content for the first slide.</p>
            </CCarouselCaption>
          </CCarouselItem >
          <CCarouselItem>
            <CImage className="d-block w-100" src={VueImg} alt="slide 2" height='450px' />
            <CCarouselCaption className="d-none d-md-block">
              <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage className="d-block w-100" src={AngularImg} alt="slide 3" height='450px' />
            <CCarouselCaption className="d-none d-md-block">
              <h5>Third slide label</h5>
              <p>Some representative placeholder content for the third slide.</p>
              <CButton color="primary">Register</CButton>
            </CCarouselCaption>
          </CCarouselItem>
        </CCarousel >
      </div>

      <PractitionerComponent />
      <EventsComponent />
      {/* <ExploreComponent /> */}
      <ConnectComponent />
      <Footer />
    </>


  )
}

export default EventHome;

